
import NavBar from "../Components/Navbar/NavBar";
import LoginComp from "../Components/Login/LoginComp";

export default function Login() {
  return (
    <section>
      <NavBar />
      <LoginComp />  
    </section>
  );

}

// from https://mdbootstrap.com/docs/standard/extended/login/