import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import "./PaymentSuccessPage.css";

function PaymentSuccessPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const myorder = queryParams.get('myorder');
    const myorder_short = myorder.substring(8,15);
    return (
        <>
            <NavBar />
            <div className="payment-container">
                <div className="payment-card">
                    <img src="https://img.icons8.com/color/96/000000/ok--v1.png" alt="Success Icon" className="success-icon"></img>
                    <h1>Payment Successful!</h1>
                    <p>Thank you for your purchase.</p>
                    <p>Your order number is <strong>{myorder_short}</strong>.</p>
                    <button className="btn-home"><a href="\">Return to Homepage</a></button>
                </div>
            </div>
        </>
    )
}
export default PaymentSuccessPage
