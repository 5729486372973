import ReportPublishingForm from "../../Components/ReportPubForm/ReportPubForm";
import NavBar from "../../Components/Navbar/NavBar";
import './ReportPublishing.css'

export default function ReportPublishing() {
  console.log("ReportPublishing");
  return (
    <div>
      <NavBar />
      <div className="Report-Publishing">
        <div>
          <ReportPublishingForm />
        </div>

        <div>
          <p>Update Filters</p>
        </div>

      </div>

    </div>

  );

}