import NavBar from "../Components/Navbar/NavBar";
import RegisterComp from "../Components/Register/RegisterComp";

export default function Login() {
  return (
    <section>
      <NavBar />
      <RegisterComp />  
    </section>
  );

}

// from https://mdbootstrap.com/docs/standard/extended/login/