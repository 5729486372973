import React, { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/NavBar";
import ReportTile from "../../Components/ReportTile/ReportTile";
import SearchFilter from '../../Components/SearchBar/SearchFilter';
import FilterTags from '../../Components/FilterTags/FilterTags';
import ToggleLeftPanel from '../../Components/Toggle/ToggleLeftPanel';
import Footer from "../../Components/Footer/Footer";
import { useLocation } from 'react-router-dom';
import './ReportResult.css';


export default function ReportResult() {
  const [isToggled, setIsToggled] = useState(false);

  const location = useLocation();
  const jsonData = location.state.ReportFilterProps;
  const applied_filters = location.state.filter_options_json;
  console.log("FilteredReportsJson: ", jsonData)

  console.log("Filters: ", applied_filters)

   // Function to handle button click
   const handleToggle = () => {
    setIsToggled(prevState => !prevState); // Toggle the state
  };
  
  if (jsonData.length === 0) {
    return (
      <div>
        <NavBar />
        <SearchFilter />
        <div className='no-data'> <p>No Such Reports Right Now</p>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className='result-body-container'>
        <div>
          <NavBar />
          <SearchFilter />
        </div>
        <div className='filterTags'>
          <FilterTags applied_filters={applied_filters} />
        </div>
        <div className='result-container'> 
          <div className='toggle-left-panel'>
            <ToggleLeftPanel />
          </div>

          <ul>
            {jsonData.map((item, index) => (
              <ReportTile
                reportName={item.ReportName}
                index={index}
                reportURL={item.ReportUrl}
                reportAuthor={item.author}
                Tags={item.tags}
                year={item.Year}
                sector={item.Sector}
                sub1={item.Sub1}
              />
            ))}
          </ul>
          
        </div>
        <Footer />
      </div>
    );
  }

}