import React, { useState } from 'react';
import Select from 'react-select';
import './SingleDropDown.css';

const SingleDropDown = (props) => {
    const [value, setValue] = useState([]);

    const options = props.options.options_list;
    const placeholder = props.placeholder;

    // const OptionsValue = (e) => {
    //     setValue([...value, e.target.value]);
    //     console.log("targetValue", e.target.value);
    // };

    const handleSubmit = (e) => {
        props.onSelect(e);
    };

       return (
        <div className='DropDown-box'>
            {options? (
            <Select 
              options={options.map((option, index) => ({
                value: option, 
                label: option  
            }))}
                defaultValue="All" placeholder={placeholder}
                onChange={(selectedOption) => {
                  //  OptionsValue(selectedOption);
                    handleSubmit(selectedOption); // Call handleSubmit on option selection
                }}
                isMulti
                isSearchable
                noOptionsMessage={() => "More ComingSoon"}
                styles={{
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "grey"
                    }),
                    clearIndicator: () => ({
                        color: "red"
                    }),
                    option: (base, state) => ({
                        ...base,
                        padding: "5px", // Adjust the padding to reduce the gap between text
                    })
                }} />    
            ) : (
                <p>Loading...</p>
              )}           
        </div>
    );
};

export default SingleDropDown;