import React, { useState, useEffect, useRef } from 'react';
import './NavBar.css';
import axios from 'axios';


function StandardNavBar() {
  const backendAPI = process.env.REACT_APP_backendAPI;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef();

  // <To open a hamburger dropdown>
  useEffect(() => {
    navRef.current = document.querySelector("#NavbarStd");
    checkAuthentication();
  }, []);

  const showNavbar = () => {
    setIsOpen(true);
    navRef.current.classList.toggle(
      "responsive_nav"
    );
  };
  // </To open a hamburger dropdown>

  //<To close the hamburger menu>
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!navRef.current.contains(event.target)) {// Click occurred outside of the hamburger menu, close the menu
        setIsOpen(false);
        navRef.current.classList.remove("responsive_nav");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Make sure to include isOpen in the dependency array...../////////
  // </ To close the hamburger menu>


  // Authentication
  function checkAuthentication ()  {
    const token = localStorage.getItem('token');
    axios.get(`${backendAPI}/AuthCheck`, 
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      })
      .then(res => {
        console.log("resData:", res.data);
        const authBoolean = res.data;
        setIsAuthenticated(authBoolean);
        
      })
    }
    // checkAuthentication();

  return (
    <div className='NavBar'>
      <div className='Logo'>
        <img className='Logo-img' src="\Assets\Images\Logo-Trans.svg" alt="MarketInsight" />
      </div>

      <div ref={navRef} id='NavbarStd' className='NavbarStd'>
        <div className='Home'>
          <a href="\">Home</a>
        </div>

        <div className="dropdown">
          <button className="dropbtn">Market Research</button>
          <img className='dropdown-img' src="\Assets\Images\Toggle.svg" alt="MarketInsight" />
          <div className="dropdown-content">
            <a href="\Reports">Reports</a>
            <a href="\Graphics">Graphics</a>
          </div>
        </div>

        <div className='Home'>
          <a href="\Pricing">Pricing</a>
        </div>

        <div className="dropdown">
          <button className="dropbtn">Resources</button>
          <img className='dropdown-img' src="\Assets\Images\Toggle.svg" alt="MarketInsight" />
          <div className="dropdown-content">
            <a href="\Insights">Insights</a>
            <a href="#">Data</a>
          </div>
        </div>
        



        <div className='SignUp'>
          {!isAuthenticated && (
            <div>
              <div className='Signup'><a className="aLink" href="\Register"> Signup </a></div>
            </div>
          )}
        </div>

        <div className='LoginLogout'>
          {isAuthenticated ? (
            <div>
              <div className='Login'> <a className="aLink" href="\Login">Logout </a></div>
            </div>
          ) : (
            // Render components for unauthenticated user
            <div>
              <div className='Login'> <a className="aLink" href="\Login">Login </a></div>
            </div>
          )}
        </div>
      </div>

      <button onClick={showNavbar} className='nav-btn'>
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M4 6l16 0" /> <path d="M4 12l16 0" /> <path d="M4 18l16 0" /> </svg>
      </button>
    </div>
  );
}

export default StandardNavBar;