import React, { useState, useEffect } from 'react';
import './Reports.css';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import SearchFilter from '../../Components/SearchBar/SearchFilter';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Carousel from '../../Components/Carousel/Carousel';
import Carousel1 from '../../Components/Carousel1/Carousel1';
import algoliasearch from 'algoliasearch';

function Reports() {
  const algoliaAppId = process.env.REACT_APP_algoliaAppId;
  const algoliaApiKey = process.env.REACT_APP_algoliaApiKey;
  const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);
  const index = searchClient.initIndex('market_content');

  const [isToggled, setIsToggled] = useState(false);
  const [arrayBlogs, setArrayBlogs] = useState([]); // State to store the blogs

  useEffect(() => {
    index.search('').then(({ hits }) => {
      setArrayBlogs(hits);
    });
  }, []);

  // Function to handle button click
  const handleToggle = () => {
    setIsToggled(prevState => !prevState); // Toggle the state
  };

  return (
    <div className='home-container'>
      <NavBar />
      <div className='search-row'>
        <div className='search-toggle'>
          <button
            onClick={handleToggle}
            className={`toggle-button ${isToggled ? 'filter' : 'search'}`}
          >
            <img className='toggle-img'
              src={isToggled ? "./Assets/Images/SearchIcon.png" : "./Assets/Images/FilterIcon.png"}
              alt={isToggled ? 'Filter' : 'Search'}
              style={{ width: '24px', height: '24px' }}  // Adjust the size as needed
            />
          </button>
          {/* <small>{isToggled ? 'Filter' : 'Search'}</small> */}
        </div>

        {isToggled &&
          <SearchFilter />
        }

        {!isToggled &&
          <SearchBar />
        }
      </div>

      <div className='home-fold1'>

      <h1 className='home-h1-title'>TOP TRENDING REPORTS</h1>
        <Carousel />


        <h1 className='home-h1-title'>KEY PUBLICATIONS</h1>
        <Carousel1 />

        <h1 className='home-h1-title'>OUR VIEWS ON TRENDING REPORTS</h1>

        <div className='blog-card-carousel'>
          {arrayBlogs.map((blog, index) => (
            <div className="blog-card">
              <div className="blog-card-content" key={index}>
                <img src={blog.bannerImage} alt={blog.contentSlug} className="blog-card-image" />
                <p>{blog.content.headline}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );

}
export default Reports;