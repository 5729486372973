import React, { useEffect, useState } from 'react';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import "./InsightsIndex.css";

export default function InsightsIndex() {
    const algoliaAppId = process.env.REACT_APP_algoliaAppId;
    const algoliaApiKey = process.env.REACT_APP_algoliaApiKey;
    const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);
    const index = searchClient.initIndex('market_content');

    const [arrayBlogs, setArrayBlogs] = useState([]); // State to store the blogs

    useEffect(() => {
        index.search('').then(({ hits }) => {
            setArrayBlogs(hits);
            console.log(hits);
        });
    }, []);

    return (
        <>
            <NavBar />
            <div className='main-containter-blogs'>
                <div className='page-title'>
                    <h3>Articles with our opinions and views and reports summaries</h3>
                </div>

                <div className='blog-categories-cont'>
                    {arrayBlogs.map((blog, index) => (

                        <div className='blog-category-box' key={index}>
                            <Link to={`/Insights/${blog.contentSlug}`}>
                                <div className='blog-tile-img'> <img src={blog.bannerImage} alt="Blog Banner" /></div>
                                <div className='blog-cat-headline'><p>{blog.content.headline}</p></div>
                                <div className='blog-name'>
                                    <ul className='blog-tile-pill1'>{blog.category}, </ul> 
                                    <ul className='blog-tile-pill2'>{blog.lastUpdate} </ul>
                                </div>
                            </Link>
                        </div>

                    ))}

                </div>
            </div>

            <Footer />
        </>
    )

}