import "./PageZero.css"
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";

function PageZero() {
    return (
        <div>
            <div className="navbar-position">
                <NavBar />
            </div>
            <div className="main-body">
                <div className="main-banner">
                    <div className="main-banner-sentence">
                        <h1>Reports & Insights <br /> from over <b>8 years</b> </h1>
                        <p>MARKET-REPORTS by SYNTHESIS is a community driven platform to help <br /> Investment Analysts & Funds gather market insights with ease.</p>
                    </div>
                    <div className="main-banner-image">
                        <img className='HeroIllustration' src=".\Assets\Images\HeroIllustration.svg" alt="MarketInsight" />
                    </div>
                </div>


                <h3 className="first-fold-title">MarketInsights in Numbers</h3>
                <div className="first-fold">

                    <div className="counter">
                        <div className="tile">
                        <p className="numbers">~50</p>
                        <p>Publications</p>
                        </div>
                    </div>

                    <div className="counter">
                        <div className="tile">
                        <p className="numbers">~200</p>
                        <p>Market Reports</p>
                        </div>
                    </div>

                    <div className="counter">
                        <div className="tile">
                        <p className="numbers">~7</p>
                        <p>Volunteers</p>
                        </div>
                    </div>

                    <div className="counter">
                        <div className="tile">
                        <p className="numbers">20</p>
                        <p>Industries</p>
                        </div>
                    </div>

                    <div className="counter">
                        <div className="tile">
                        <p className="numbers">50</p>
                        <p>Tags</p>
                        </div>
                    </div>

                    <div className="counter">
                        <div className="tile">
                        <p className="numbers">15</p>
                        <p>Clients</p>
                        </div>
                    </div>
                </div>

                <div className="whyUs">
                    <div className="usp">
                        <div className="usp-box">
                            <p><b>Quick Research</b></p>
                            <div className="usp-text">
                                <p>Quick glace though douzens of reports in few clicks saving time on endless google searches</p>
                            </div>
                        </div>
                    </div>

                    <div className="usp">
                        <div className="usp-box">
                            <p><b>Tagged Reports</b></p>
                            <div className="usp-text">
                                <p>Each report is tagged with Searchable Keywords making it easy to preempt what to expect in the reports</p>
                            </div>
                        </div>
                    </div>

                    <div className="usp">
                        <div className="usp-box">
                            <p><b>Support on Request</b></p>
                            <div className="usp-text">
                                <p>Drop us a line if you are looking for report coverage on any specific sector or sub-sector. We go you covered!!</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="register-with-us">
                    <div className="register-box">
                        <button className="rounded-button"><a href="\Register"> Register with us?</a></button>
                    </div>
                </div>



                <div className="footer">
                    <Footer />
                </div>
            </div>
        </div>
        
    );
}
export default PageZero



