
const backendAPI = process.env.REACT_APP_backendAPI;


export async function fetchDataFromAPI(requestBody) {
    try {
      const response = await fetch('https://jsonplaceholder.typicode.com/users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("response api.js:",data);
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

    export async function fetchSetorSubOptions() {
      try {
        const response = await fetch(`${backendAPI}/UpdateEndpoint/sectorsOptions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let data = await response.json();
        console.log("response api.js:",data);
        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

  export async function fetchAuthors() {
    try {
      const response = await fetch(`${backendAPI}/CRUD/get/Authors`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
       
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let data = await response.json();
      console.log("response api.js:",data);
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  export async function fetchYears() {
    try {
      const response = await fetch(`${backendAPI}/UpdateEndpoint/years`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
       
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let data = await response.json();
      console.log("response api.js:",data);
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  export async function fetchTags() {
    try {
      const response = await fetch(`${backendAPI}/CRUD/get/Tags`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
       
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      let data = await response.json();
      console.log("response api.js:",data);
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }