

const backendAPI = process.env.REACT_APP_backendAPI;



export function CreateUserId(email, password) {
    const reponse = fetch(`${backendAPI}/CreateUserId`, {
      method: 'POST', // or 'GET', 'PUT', etc.
      headers: {
        'Content-Type': 'application/json',
        // Additional headers if needed
      },
      body: JSON.stringify({ "username": email, "password": password }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response
        console.log(data);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }
  
