import React from 'react';
import { useState } from 'react';
import './ReportTile.css';


function ReportTile({ index, Tags, reportURL, reportAuthor, reportName, year, sector, sub1}) {
  const [truncated, setTruncated] = useState(true);
  const longText = Tags;

  return (

    <div className="card">

      <div className="card-content">
        <div className='card-top-row'>
          <div className='card-title'>
          <p><b>ReportName: </b><a className= "ReportURL" href={reportURL}>{reportName}  </a></p>
          </div>
          <div className='counter'>
          {index+1}
          </div>
        </div>
        <div className='card-parameters card-text'>
          <div><b>Sector: </b>{sector} </div>
          <div><b>Sub-Sector: </b>{sub1}</div>
        </div>

        <div className='card-parameters card-text'>
          <div><b>Published Year: </b>{year} </div>
          <div><b>Publisher/Author: </b>{reportAuthor}</div>
        </div>

        {/* <div className='card-text'>
          Tags:
        </div> */}

      </div>
    </div>
  );

}
export default ReportTile;



