import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch';
import { CreateUserId } from '../../Api/UtilFunctions';
import "./Insights.css";


export default function Insights() {
    const backendAPI = process.env.REACT_APP_backendAPI;
    const [found, setFound] = useState(null);
    const [headline, setHeadline] = useState(null);
    const [category, setCategory] = useState(null);
    const [metaDescription, setMetaDescription] = useState(null);
    const [title, setTitle] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [mainBody, setMainBody] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [authorImage, setAuthorImage] = useState("");
    const [datePublished, setdatePublished] = useState(null);
    const [dateModified, setDateModified] = useState(null);
    const [showPopup, setShowPopup] = useState(false); // for popup
    const [email, setEmail] = useState('');
    const [PasswordPop, setPasswordPop] = useState('');
    const [authenticationFailed, setAuthenticationFailed] = useState(false);
    const navigate = useNavigate();
    const type = "Article";

    const articleSchema = {
        "@context": "http://schema.org",
        "@type": "Article",
        "headline": {headline},
        "author": {
            "@type": "Person",
            "name": {authorName}
        },
        "datePublished": {datePublished},
        "image": {
            "@type": "ImageObject",
            "width": 1000,
            "url": {bannerImage}
        },
        "publisher": {
            "@id": "https://marketreports.in",
            "@type": "Organization",
            "name": "MarketReports-by SYNTHESIS",
            "logo": {
                "@type": "ImageObject",
                "url": "https://storage.googleapis.com/marketreports/Brand/Logo/Logo-Trans.svg",
                "width": "600",
                "height":"60"
            }
        },
        "keywords": `${category}, Market Research, Market Reports, Industry Reports `,
        "mainEntityOfPage" : "https://marketreports.in/Insights",
        "inLanguage": "EN",
        "isAccessibleForFree": "true",
        "datePublished": {datePublished},
        "dateModified": {dateModified},
        "description": {metaDescription}
    };

    const popupStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    const popupContentStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '30%',
    };
    const inputStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    };
    const buttonStyle = {
        padding: '10px 20px',
        margin: '10px 10px',
        backgroundColor: '#3498db',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };
    const closeBtnStyle = {
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        padding: '5px',
        cursor: 'pointer',
        borderRadius: '5px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '1px 10px'
    };


    const params = useParams();
    const token = localStorage.getItem('token');
    const algoliaAppId = process.env.REACT_APP_algoliaAppId;
    const algoliaApiKey = process.env.REACT_APP_algoliaApiKey;
    const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);
    // const searchClient = algoliasearch('0K9MUXZLQ5', 'e6e35f0afb6f89783df879a4c8ee0733')
    const index = searchClient.initIndex("market_content");
    index.findObject(hit => hit.contentSlug === params.slug)
        .then(obj => {
            setFound(obj.object.contentSlug === params.slug);
            setHeadline(obj.object.content.headline);
            setTitle(obj.object.title);
            setCategory(obj.object.category);
            setMetaDescription(obj.object.metaDescription);
            setMainBody(obj.object.content.mainBody);
            setdatePublished(obj.object.lastUpdate);
            setBannerImage(obj.object.bannerImage);
            setAuthorImage(obj.object.authorImage);
            setAuthorName(obj.object.authorName);
        });

    useEffect(() => {
        let randomNum = Math.floor(Math.random() * (30 - 7)) + 7; //gives a random number b/w 7 and 30
        let currentDate = new Date();
        let modifiedUpdatedDate = new Date();
        modifiedUpdatedDate.setDate(currentDate.getDate() - 7);
        setDateModified(modifiedUpdatedDate);
    
        const handleScroll = () => {
            // Check if the user has our cookiee in its browser
            if (localStorage.getItem('token') == null) {
                // Check if user has scrolled to the bottom
                if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) {
                    setShowPopup(true);
                    console.log("Reached bottom");
                }
            }
        };
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogin = (event) => {
        event.preventDefault();
        axios.post(`${backendAPI}/UserLogin`, {
            username: email,
            password: PasswordPop
        })
            .then((response) => {
                console.log("Token:  ", response.data);
                setShowPopup(false);
                localStorage.setItem('token', response.data);

                if (response.data === "Incorrect Credentials or Try Registering") {
                    authenticationFailed = "Yes"
                    console.log("authenticationFailed: ", authenticationFailed);
                }

            }, (error) => {
                console.log(error);
                setAuthenticationFailed(true);
            });
        console.log("fetched: ", localStorage.getItem('token'))
    }

    const handleRegister = (event) => {
        event.preventDefault();
        CreateUserId(email, PasswordPop);
        navigate('/Login', { state: {} });
        setShowPopup(false); // Close the pop-up after submission
    };


    return (
        <>
            <Helmet>
                {/* Inject JSON-LD schema into the head of the document */}
                <script type="application/ld+json">
                    {JSON.stringify(articleSchema)}
                </script>
                <meta name="description" content={metaDescription}></meta>
                <title>{title}</title>
            </Helmet>
            <NavBar />
            <div className='insights-container'>
                <div className='article-title'> <h1>{headline}</h1>
                </div>

                <div className='article-banner-img'>
                    <img className='article-img' src={bannerImage} alt="MarketReport on Industries" width="100%" height="300"></img>
                </div>

                <div className='article-author-container'>
                    <img className='author-img' src={authorImage} alt="Blog Author"></img>
                    <div className='author-details'><p className='author-name'>{authorName}</p>
                        <p className='last-update'>{datePublished}</p></div>

                </div>

                <div className='article-content'>
                    {parse(mainBody)
                    }
                </div>
                <div className='article-tags'></div>
                {showPopup && (
                    <div style={popupStyle}>
                        <div style={popupContentStyle}>
                            <button onClick={() => setShowPopup(false)} style={closeBtnStyle}> X </button>
                            <h2>Register & Subscribe</h2>
                            <p>We charge so low, you wouldn't bother to pay</p>
                            {/* <form onSubmit={handleSubmit}> */}
                            <form>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={inputStyle}
                                />
                                <input
                                    type="password"
                                    placeholder="Enter your password"
                                    value={PasswordPop}
                                    onChange={(e) => setPasswordPop(e.target.value)}
                                    required
                                    style={inputStyle}
                                />
                                <button type="submit" style={buttonStyle} onClick={handleLogin}>Log-in</button>
                                <button type="submit" style={buttonStyle} onClick={handleRegister}>Signup</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Footer />

        </>
    )
}
