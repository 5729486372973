import React from 'react';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import "./Graphics.css";


export default function Graphics() {
  return (
    <div>
      <NavBar />

      <div className='graphicsc-container'>

        <div className='disclaimer'>
          More on this... Soon
        </div>
        <div className='graphics-sub-container'>
          <div className='gr-img-div'>
            <img className='gr-img-item' src="./Assets/Images/GraphicsPage/MobileProd.png" />
          </div>
          <div className='gr-img-div'>
            <img className='gr-img-item' src="./Assets/Images/GraphicsPage/IPOIndia.jpeg" />
          </div>
          <div className='gr-img-div'>
            <img className='gr-img-item' src="./Assets/Images/GraphicsPage/DigitalRev.png" />
          </div>
          <div className='gr-img-div'>
            <img className='gr-img-item' src="./Assets/Images/GraphicsPage/2EletricleVehicle.png" />
          </div>
        </div>
      </div>
      <Footer />

    </div>

  );

}

// export default Graphics;