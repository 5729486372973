import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { load } from '@cashfreepayments/cashfree-js';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import PaymentSuccessPage from '../../Pages/Payments/PaymentSuccessPage';
import './Pricing.css';

function Pricing() {
  const backendAPI = process.env.REACT_APP_backendAPI;
  const frontendAPI = process.env.REACT_APP_frontendAPI;
  const environment = process.env.REACT_APP_environment;
  const price1 = 1;
  const price2 = 3;
  const price3 = 5;

  const [emailID, setEmailID] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let cashfree;
  const navigate = useNavigate();



  const sessionToken = async () => {
    try {
      const token = localStorage.getItem('token');
      return token;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const fetchAndDecodeToken = async () => {
      const token = await sessionToken();
      if (token) {
        const decodedToken = jwtDecode(token);
        const emailID = decodedToken.sub;
        setEmailID(emailID);
      }
    }
    fetchAndDecodeToken();
    checkAuthentication();
  }, [])


  const handleClick = (price) => {
    if (isAuthenticated) {
      axios.post(`${backendAPI}/CashfreeCreateOrder`, {
        mailID: emailID,
        planAmt: price
      })
        .then(response => {
          console.log('SessionID: ', response.data);
          const orderID = response.data;  // Assuming response.data is the order ID
          return orderID; // Return something to the next .then() block, if needed
        })
        .then(orderID => { // This .then() block receives orderID from the previous .then() block
          let checkoutOptions = {
            paymentSessionId: orderID,
            returnUrl: `${frontendAPI}/PaymentSuccessPage?myorder=${orderID}`
          }

          cashfree.checkout(checkoutOptions).then(function (result) {
            if (result.error) {
              alert(result.error.message)
            }
            if (result.redirect) {
              console.log("Redirection")
            }
          });
        })
        .catch(error => {
          console.error('There was an error creating the order:', error);
        });
    }
    else {
      navigate('/Login'); // redirect to login first before going for plan purchase
    }
  };


  let initializeSDK = async function () {
    if (environment === "UAT") {
      cashfree = await load({
        mode: "sandbox"
      });
    }
    else {
      cashfree = await load({
        mode: "production" //or production
      });
    }
    console.log("ENV: ", environment)
  }

  initializeSDK();

  // Authentication
  function checkAuthentication() {
    const token = localStorage.getItem('token');
    axios.get(`${backendAPI}/AuthCheck`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      })
      .then(res => {
        console.log("authenticated?", res.data);
        const authBoolean = res.data;
        setIsAuthenticated(authBoolean);
      })
  }



  return (
    <>
      <NavBar />
      <div className="pricing-container">
        <h1 className="pricing-title">Pricing Plans</h1>
        <p>MarketReports is a Brand Company owned by Synthesis </p>
        <div className="pricing-cards-list">
          <div className="pricing-card">
            <h2>Try it Out</h2>
            <p className="price">₹{price1}<span>/month</span></p>
            <ul>
              <li>10 Searchs per day </li>
              <li>50 download credits</li>
              <li>Email Support</li>
            </ul>
            <button className="pricing-button" onClick={() => handleClick({ price1 })}>Get Started</button>

          </div>

          <div className="pricing-card">
            <h2>Standard</h2>
            <p className="price">₹ {price2}<span>/6 months</span></p>
            <ul>
              <li>50 Searches per day</li>
              <li>500 download credits</li>
              <li>Priority Support</li>
            </ul>
            <button className="pricing-button-2" onClick={() => handleClick({ price2 })}>Good Choice</button>
          </div>

          <div className="pricing-card">
            <h2>Enterprise</h2>
            <p className="price">₹ {price3}<span>/year</span></p>
            <ul>
              <li>Unlimited Searches</li>
              <li>Unlimited downloads</li>
              <li>24/7 Support</li>
            </ul>
            <button className="pricing-button" onClick={() => handleClick({ price3 })}>Be a Pro</button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );


}
export default Pricing;