import React, { useState } from 'react';
import './Toggle.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Toggle = (props) => {
    const backendAPI = process.env.REACT_APP_backendAPI;
    const navigate = useNavigate();
    const drowdownOptions = props.options
    const header = props.header
    const [isOpen, setIsOpen] = useState(true);
    const AuthToken = localStorage.getItem('token');
    const filter_options_json = {};



    // Function to toggle the dropdown
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const searchReports = (kind, value) => {
        filter_options_json['sector_filters'] = { "sub1": value, "year": null, "author": null, "tags": null };
        console.log(kind, value);
        axios.get(`${backendAPI}/SearchReportWithKind?kind=${kind}&value1=${value}`,
            {
                headers: {
                    "Authorization": `Bearer ${AuthToken}`,
                }
            })
            .then(res => {
                console.log("API: ", `${backendAPI}/SearchReportWithKind?kind=${kind}&value1=${value}`)
                console.log("resData:", res.data);
                const ReportFilterProps = res.data;
                if (ReportFilterProps.message === "Update plan") {
                    console.log(ReportFilterProps.message)
                    navigate('/Pricing', { state: {} })
                } else {
                    navigate('/ReportResult', { state: { ReportFilterProps, filter_options_json } })
                }
            })
    }

    return (
        <div className='left-pane-search-result-parent' >
            {/* Button to toggle the dropdown */}
            <button onClick={toggleDropdown}>{header}</button>

            {/* Dropdown content */}
            {isOpen && (
                <div>
                    <ul className='subheader-list'>
                        {drowdownOptions.map(option => (
                            <li >
                                <small onClick={() => searchReports(option.Kind, option.Key)}>{option.Key}</small>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Toggle;
