import React from 'react';
import './RefundPolicy.css';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";

function RefundPolicy() {

    return (
        <>
            <NavBar />
            <div className='headerRefund'>
                <h1>Cancellation & Refund Policy</h1>
            </div>

            <div className='main-container'>

                <div>
                    <p><strong>
                        SYNTHESIS (MarketReports) believes in helping its customers as far as possible, and has therefore a liberal cancellation
                        policy as briefed below. Under this policy</strong>
                    </p>
                    <p>
                        • Cancellations will be considered only if the request is within 24 hours after placing the order.
                    </p>
                    <p>
                        • SYNTHESIS does not accept cancellation requests after downloading any reports from the platform. Your cancellation request will only be entertained if you have not extensively utilized the platform.
                    </p>

                    <p>
                        • In case you feel that the product received is not as per your expectations, you must bring it to our notice within 2 Days days of subscribing to the product. Our Team after
                        looking into your complaint will take an appropriate decision for improvement of the product and further customer experience.
                    </p>
 
                    <p>
                        • In case of any Refunds approved by SYNTHESIS, it usually takes 3-5 Days days for the
                        refund to be processed and money being credited to the end customer's bank account.
                    </p>
                </div>

            </div>

            <Footer />

        </>
    )


}

export default RefundPolicy;