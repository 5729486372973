import React, { useState } from 'react';

import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";
import './ContactUs.css';

function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here, e.g., send the data to a server.
        setSubmitted(true);
        setName('');
        setEmail('');
        setMessage('');
    };

    return (
        <>
        <NavBar />
        <div className="contact-container">
            <h1>Contact Us</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="submit-button">Submit</button>
                {submitted && <p className="success-message">Thank you for contacting us!</p>}
            </form>

            <div className='Adress-box'>
                <h3 className='Adress-box-header'>Write to use at below </h3>
                <p> <strong>Legal Entity Name:</strong> SYNTHESIS <br></br>
                    <strong>Registered Address: </strong>K 154, MIDC Area, Waluj,
                    Maharashtra, 431133<br></br>
                    <strong>Telephone No: </strong> 8958007911<br></br>
                    <strong>E-Mail ID: </strong>admin@marketreports.in
                </p>
            </div>
        </div>
        <Footer />
        </>
    );
}

export default ContactUs;
