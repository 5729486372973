import React, { useState, useEffect, useCallback } from 'react';
import './CascadingDropDown.css';

const CascadingDropDown = ({ options, onSelect }) => {
    const { sectors, sub1 } = options;

    const [selectedSector, setSelectedSector] = useState('');
    const [selectedSub1, setSelectedSub1] = useState('');

    const handleSectorChange1= (e) => {
        console.log(e.target.value);
        setSelectedSector(e.target.value);
    }

    const handleSubChange1= (e) => {
        console.log(e.target.value);
        setSelectedSub1(e.target.value);
    }

    useEffect(() => {
        const sec_options_json = {
            sector: selectedSector,
            sub1: "",
        };
        onSelect(sec_options_json);
    }, [selectedSector]);

    useEffect(() => {
        const sec_options_json = {
            sector: selectedSector,
            sub1: selectedSub1,
        };
        onSelect(sec_options_json);

    }, [selectedSub1]);



    return (
        <div className='cascading-dropdown'>
            <div className='select-box-div'>
                {/* <select id="select-box" value={selectedSector} onChange={handleSectorChange}> */}
                <select id="select-box" value={selectedSector} onChange={handleSectorChange1}>
                    <option value="" disabled>Select a sector</option>
                    {sectors.map((sector, index) => (
                        <option key={index} value={sector}>
                            {sector}
                        </option>
                    ))}
                </select>
            </div>
            <div className='select-box-div'>
                <small>
                    {/* <select id="select-box" value={selectedSub1} onChange={handleSub1Change}> */}
                    <select id="select-box" value={selectedSub1} onChange={handleSubChange1}>
                        <option value="" disabled>Select a Sub-sector</option>
                        {selectedSector ? (
                            sub1[selectedSector]?.map((sub, index) => (
                                <option key={index} value={sub}>
                                    {sub}
                                </option>
                            ))
                        ) : (
                            <option disabled>Select SubSector</option>
                        )}
                    </select>
                </small>
            </div>
        </div>
    );
};

export default CascadingDropDown;
