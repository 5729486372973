import React, { useState } from 'react';
import './ShowSuggestions.css';


const showSuggestions = (props) => {
  const valu = props.suggestions;
  console.log("CHeck: ", valu)

  return (
    <div className='suggestion-dropdown'>
      <ul className="suggestion-dropdown-ul">
        {
          props.suggestions.map((value, index) => (
            <li className="sugg-line-item" key={index}>
              <a href={value.value} onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior for custom handling
                props.suggestionClick(value.value);
                // window.location.href = value.value; // Navigate to the URL
              }}>
                {value.key}
              </a>
            </li>
          )
          )
        }
      </ul>
    </div>
  )
}

export default showSuggestions;
