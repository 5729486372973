import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginComp.css';

const LoginComp = () => {
  const backendAPI = process.env.REACT_APP_backendAPI;
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticationFailed, setAuthenticationFailed] = useState(false);

  const updateUsername = (e) => {
    setUsername(e.target.value)
  }

  const updatePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = () => {
    axios.post(`${backendAPI}/UserLogin`, {
      username: username,
      password: password
    })
      .then((response) => {
        console.log("Token:  ", response.data);
        navigate('/', { state: { username } });
        localStorage.setItem('token', response.data);

        if (response.data == "Incorrect Credentials or Try Registering") {
          authenticationFailed = "Yes"
          console.log("authenticationFailed: ", authenticationFailed);
        }

      }, (error) => {
        console.log(error);
        setAuthenticationFailed(true);
      });
    console.log("fetched: ", localStorage.getItem('token'))
  }

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid" alt="" />
          </div>

          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1 login-div">
            <form>
              <p><b>Login to Search Reports </b></p>
              {authenticationFailed && (
                <div className='discalmaier'>
                  <p>Incorrect Password. Try Again</p>
                </div>
              )}

              <div className="form-outline mb-4">
                <input type="email" id="form3Example3" className="form-control form-control-lg"
                  placeholder="Email address" style={{ opacity: 0.4 }} value={username} onChange={updateUsername} />
              </div>
              <div className="form-outline mb-3">
                <input type="password" id="form3Example4" className="form-control form-control-lg"
                  placeholder="Enter password" style={{ opacity: 0.4 }} value={password} onChange={updatePassword} />
              </div>

              <div className="d-flex justify-content-between align-items-center">
                {/* Checkbox */}
                <div className="form-check mb-0">
                  <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>
                <a href="#!" className="text-body">Forgot password?</a>
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button type="button" className="btn btn-primary btn-lg" style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
                  onClick={handleLogin} >Login</button>
                <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="./Register"
                  className="link-danger">Register</a></p>
              </div>

            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginComp;
