import React, { useState, useEffect } from 'react';
import './SearchFilter.css';
import SingleDropDown from "../DropDowns/SingleDropDown.jsx";
import CascadingDropDown from "../CascadingDropDown/CascadingDropDown.jsx";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchSetorSubOptions, fetchAuthors, fetchYears, fetchTags } from "../../Api/Api.js";

const backendAPI = process.env.REACT_APP_backendAPI;

const SearchFilter = () => {
  const navigate = useNavigate();
  const [SecSubdata, setSecSubdata] = useState([]);
  const [Authordata, setAuthordata] = useState([]);
  const [Yeardata, setYeardata] = useState([]);
  const [Tagdata, setTagdata] = useState([]);

  useEffect(() => {
    async function getData() {
      const OptionsSub1actualData = await fetchSetorSubOptions();
      setSecSubdata(OptionsSub1actualData);
    }
    getData()
  }, [])

  useEffect(() => {
    async function getData() {
      const OptionsAuthorData = await fetchAuthors();
      setAuthordata(OptionsAuthorData);
    }
    getData()
  }, [])

  useEffect(() => {
    async function getData() {
      const OptionsYearData = await fetchYears();
      setYeardata(OptionsYearData);
    }
    getData()
  }, [])

  useEffect(() => {
    async function getData() {
      const OptionsTagData = await fetchTags();
      setTagdata(OptionsTagData);
    }
    getData()
  }, [])


  if (SecSubdata.length === 0 || Yeardata.length === 0 || Authordata.length === 0) {
    return (
      <div style={{ marginLeft: "40%"}} >
        <img src="./Assets/Gifs/loading.gif" alt="Loading..." width="100" height="80"/>
      </div>
    );
  }


  const filter_options_json = {};
  const year_placeholder = "Select Year"
  const author_placeholder = "Select Author"

  const getYear = (data) => {
    console.log("Coming from child Year", data);
    filter_options_json['year'] = data;
  }

  const getAuthor = (data) => {
    console.log("Coming from child Author", data);
    filter_options_json['author'] = data;
  }

  const getSectorFilters = (data) => {
    console.log("Coming from child Sectors", data);
    filter_options_json['sector_filters'] = data;
  }

  const getTags = (data) => {
    console.log("Coming from child Sectors", data);
    filter_options_json['tags'] = data;
  }

  const FinalFilters = () => {
    const AuthToken = localStorage.getItem('token');
    console.log("FinalFilters", filter_options_json);
    console.log("AuthToken:  ", AuthToken);

    function checkAuth(success) {
      if (!success) {
        console.log("Login Needed");
        navigate('/Login', { state: {} })
      } else {
        SearchReports();
      }
    }

    function SearchReports() {
      axios.post(`${backendAPI}/SearchReports`, filter_options_json,
        {
          headers: {
            "Authorization": `Bearer ${AuthToken}`,
          }
        })
        .then(res => {
          console.log("resData:", res.data);
          const ReportFilterProps = res.data;
          if (ReportFilterProps.message === "Invalid Authorization") {
            console.log(ReportFilterProps.message)
            navigate('/Login', { state: {} })
          }
          else if (ReportFilterProps.message === "Update plan") {
            console.log(ReportFilterProps.message)
            navigate('/Pricing', { state: {} })
          } else {
            navigate('/ReportResult', { state: { ReportFilterProps, filter_options_json } })
          }
        })
    }
    SearchReports();
  };

  return (
    <>
      <div className="container-search-filter">
        <div >
          <CascadingDropDown options={SecSubdata} onSelect={getSectorFilters} />
        </div>

        <div className='single-dropdown'>
          <SingleDropDown options={Authordata} placeholder={author_placeholder} onSelect={getAuthor} />
        </div>

        <div className='single-dropdown'>
          <SingleDropDown options={Yeardata} placeholder={year_placeholder} onSelect={getYear} />
        </div>

        <div className='single-dropdown'>
          <SingleDropDown options={Tagdata} placeholder="Select Tag" onSelect={getTags} />
        </div>


        <div className='Search-Container'>
          <button type="button" className="search-button" onClick={FinalFilters}>Go</button>
        </div>
      </div>
    </>
  );
}

export default SearchFilter;
