import React from 'react';
import axios from 'axios';
import TestComponent from "../Components/TestComponent/TestComponent.jsx";
import './Test.css';

const Test = () => {


  return (
    <>

      <div class="blog-card">
        <img src="https://storage.googleapis.com/marketreports/Blogs/Banners/screen-with-graph-it-that-says-word-financial-it.jpg"
          alt="Mumbai Nagpur Expressway" class="blog-card-image" />
        <div class="blog-card-content">
          <h3>Mumbai Nagpur Expressway - Features, Highlights, Tourist Destinations</h3>
          <p>Explore the transformative Samruddhi Mahamarg project, connecting Mumbai and Nagpur, enhancing connectivity and...</p>
          <span class="blog-card-date">3 months ago</span>
        </div>

      </div>

    </>
  );
};

export default Test;