import './App.css';
import { Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Reports from './Pages/Reports/Reports';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Pricing from './Pages/Pricing/Pricing';
import ReportResult from './Pages/Registered/ReportResult';
import ReportPublishing from './Pages/Publishing/ReportPublishing';
import TandC from './Pages/Footer/TandC';
import ContactUs from './Pages/Footer/ContactUs';
import RefundPolicy from './Pages/Footer/RefundPolicy';
import Privacy from './Pages/Footer/Privacy';
import Test from './Pages/Test';
import Graphics from './Pages/Content/Graphics';
import PageZero from './Pages/PageZero/PageZero';
import Insights from './Pages/Insights/Insights';
import InsightsIndex from './Pages/InsightsIndex/InsightsIndex';
import PaymentSuccessPage from './Pages/Payments/PaymentSuccessPage';


export default function App() {
  const webSiteSchema = {
    "keywords": "Market Reports, Startups, Industry Reports, Sector Reports"
  }

  return (
    <>
      <Helmet>
        {/* Inject JSON-LD schema into the head of the document */}
        <script type="application-website/ld+json">
          {JSON.stringify(webSiteSchema)}
        </script>
       
      </Helmet>
      <Routes>
        <Route path="/Reports" element={<Reports />} />
        <Route path="/" element={<PageZero />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/ReportResult" element={<ReportResult />} />
        <Route path="/ReportPublishing" element={<ReportPublishing />} />
        <Route path="/Graphics" element={<Graphics />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/TandC" element={<TandC />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Insights" element={<InsightsIndex />} />
        <Route path="/Insights/:slug" element={<Insights />} />
        <Route path="/PaymentSuccessPage" element={<PaymentSuccessPage />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </>

  );
}