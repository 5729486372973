import React from 'react';
import './Privacy.css';
import NavBar from "../../Components/Navbar/NavBar";
import Footer from "../../Components/Footer/Footer";

function Privacy() {

    return (
        <>
            <NavBar />
            <div className='headerprivacy'>
                <h1>Privacy Policy</h1>
            </div>

            <div className='main-container'>

                <div>
                    <p><strong>
                        SYNTHESIS (MarketReports) believes in helping its customers as far as possible, and has therefore a liberal cancellation
                        policy as briefed below. marketreports.in is a product owned and operated by a propritarship company - Synthesis (hereinafter referred to as MarketReports”, “us”, “we”). MarketReports is committed to safeguarding any information submitted to us on our website (“Site”). The privacy notice provides you with a summary of the type of information collected by us, policy regarding use of that information and measures taken to safeguard that information. Under this policy </strong> </p>
                    <p>
                        <strong> User Consent and Agreement:  </strong>
                        By submitting Personal Information through our Platform or Services, you agree to the terms of this Privacy Policy and you expressly give your voluntary consent to the collection, use and disclosure of the Personal Information in accordance with this Privacy Policy.
                    </p>
                    <p>
                        <strong> Information you provided to us </strong>
                        <li>If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply.</li>
                        <li>When you participate in one of our surveys, we may collect additional information.</li>
                        <li>We may collect Personal and financial information provided by you, such as your first and last name, phone, e-mail address, age, investments, PAN etc. while you access services on the platform</li>
                        <li>We may also collect Personal Information on the Platform or through the Services when we make clear that we are collecting it and you voluntarily provide the same, for example when you submit an application for employment.</li>

                    </p>

                    <p>
                        <strong>  Use of personal information:   </strong>
                        In general, personal Information you submit to us regarding you or your company is used either to respond to requests that you make, or to aid us in serving you better. We use such Personal Information in the following ways:

                        <li>To identify you as a user in our system;</li>
                        <li>To provide improved administration of our Platform and Services;</li>
                        <li>To provide the Services you request;</li>
                        <li>To improve the quality of experience when you interact with our Platform and Services;</li>
                        <li>To send you e-mail and other notifications;</li>
                        <li>To send newsletters, surveys, offers, and other promotional materials related to our Services and for other marketing purposes.</li>
                        <li>Protect our services and our users.</li>
                        <li>Market and customer analysis, market research and statistics.</li>
                        <li>To prevent and detect fraud or abuse of our services as well as to prevent, detect and investigate any potential unlawful or prohibited activities.</li>
                    </p>

                    <p>
                        <strong> Creation of anonymous information </strong>
                        <li> We may create Anonymous Information records from Personal Information by excluding information (such as the name and email). We use this Anonymous Information to analyze request and usage patterns so that we may enhance the content of our Services and improve Platform navigation. We reserves the right to use Anonymous Information for any purpose and disclose Anonymous Information to third parties at our sole discretion. </li>
                    </p>

                    <p>
                        <strong> Third party websites </strong>
                        <li> When you click on a link to any other website or location, you will leave our Platform and go to another site and another entity may collect Personal Information or Anonymous Information from you. We have no control over, do not review, and cannot be held responsible for, these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any collection of information after you click on links to such outside websites. </li>
                    </p>

                    <p>
                        <strong> Cookies:   </strong>
                        "Cookies" are pieces of information that a browser can record after visiting a website. We may use cookies for technical purposes such as to enable better navigation through our site, or to store user preferences for interacting with the site. If you turn off the option to have cookies stored on your browser, it will affect your experience on marketreports.in
                    </p>

                    <p>
                        <strong> Retention of information/data:  </strong>
                        It may be noted that we may retain the data shared with us till such time as may be provided under the law and as required to efficiently provide service to our users.
                        For any data as well as consent that you may have provided and want to be deleted or revoked (fully or partially), please send an email (refer to the contact-us section of the website) stating the details of the data and/or consent provided and the scope/nature of the deletion/revocation desired.
                        However, in the event of you withdrawing such consent or making a request to delete the data, you would not be able to use the Services on the Platform.
                    </p>

                    <p>
                    <strong> Amendments/Modifications:  </strong>
                    This Privacy Policy available on our website is current and valid. However, marketreports.in reserves the right to amend/modify any of the sections of this policy at any time and the general public are requested to keep themselves updated for changes by reading the same from time to time.
                    </p>
                </div>

            </div>

            <Footer />

        </>
    )


}

export default Privacy;