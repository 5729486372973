import React from 'react';
import './Footer.css';


function Footer() {
  return (
    <div id="footer-container" className='footer-container'>
    
    <div className="footer">
      
      <small>
        MarketReports by Synthesis   |  Copyright ©2024
      </small>
      
    </div>

    <div className='misc-tabs1'> <small><a className="aLink" href="\TandC">Terms & Conditions </a></small></div>
    <div className='misc-tabs1'> <small><a className="aLink" href="\RefundPolicy"> Refund Policy </a></small></div>
    <div className='misc-tabs3'> <small><a className="aLink" href="\ContactUs"> ContactUs </a></small></div>
    <div className='misc-tabs3'> <small><a className="aLink" href="\Privacy"> Privacy </a></small></div>
    </div>

  );
}

export default Footer;