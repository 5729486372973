import React, { useEffect } from 'react';
import './Carousel1.css';

function Carousel1(props) {
  const img_url_map = props.img_url_map
  useEffect(() => {
    const prev = document.getElementById('prev-btn');
    const next = document.getElementById('next-btn');
    const list = document.getElementById('item-list');

    const itemWidth = 150;
    const padding = 10;

    const handlePrevClick = () => {
      list.scrollLeft -= itemWidth + padding;
    };

    const handleNextClick = () => {
      list.scrollLeft += itemWidth + padding;
    };

    if (prev && next && list) {
      prev.addEventListener('click', handlePrevClick);
      next.addEventListener('click', handleNextClick);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (prev && next) {
        prev.removeEventListener('click', handlePrevClick);
        next.removeEventListener('click', handleNextClick);
      }
    };
  }, []); 

  return (
    <div className="container">
      <div className="carousel-view">
        <button id="prev-btn" class="prev-btn">
          <svg viewBox="0 0 512 512" width="20" title="chevron-circle-left">
            <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z" />
          </svg>
        </button>
        <div id="item-list" class="item-list">
          <a href='https://storage.googleapis.com/marketreports/Reports/Annual%20Status%20of%20Education%20Report%202022.pdf'> <img id="item" className="item1" src="./Assets/Images/HomePage/nascomm.jfif" /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/EdTech_The_Advent_of_Digital_Education.pdf'><img id="item" className="item1" src="./Assets/Images/HomePage/Government_of_India_logo.svg" /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/Annual%20Status%20of%20Education%20Report%202022.pdf'> <img id="item" className="item1" src="./Assets/Images/HomePage/McKinsey.svg" /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/nasscom-India-The-Tech-Talent-Nation-Final-Dec%202023.pdf'> <img id="item" className="itemHDFC" src="./Assets/Images/HomePage/HDFC.png"  /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/nasscom-India-The-Tech-Talent-Nation-Final-Dec%202023.pdf'> <img id="item" className="itemHDFC" src="./Assets/Images/HomePage/RBI.PNG" /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/edtech-2023-road-ahead-pgalabs.pdf'> <img id="item" className="item1" src="./Assets/Images/HomePage/KPMG_logo.svg" /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/Annual%20Status%20of%20Education%20Report%202022.pdf'> <img id="item" className="item1" src="./Assets/Images/HomePage/Bloomberg.png" /> </a>
          <a href='https://storage.googleapis.com/marketreports/Reports/Annual%20Status%20of%20Education%20Report%202022.pdf'> <img id="item" className="item1" src="./Assets/Images/HomePage/cii.png" /> </a>
        </div>
        <button id="next-btn" class="next-btn">
          <svg viewBox="0 0 512 512" width="20" title="chevron-circle-right">
            <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Carousel1;
